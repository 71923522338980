













































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {PhotoInterface, PhotoShortInterface} from '@/types/photoInterface';
import Loader from '@/components/Loader.vue';
import {vxm} from '@/store';
import BuildFormDataMixin from '@/mixins/BuildFormDataMixin';
import ProgressBar from '@/components/ProgressBar.vue';
import Vuelidate from 'vuelidate';
import {required} from 'vuelidate/lib/validators';

Vue.use(Vuelidate);

@Component({
  components: {
    ProgressBar,
    Loader,
    Modal,
  },
  validations: {
    form: {
      name: {required},
    },
  },
})
export default class EditPhoto extends Mixins(BuildFormDataMixin) {
  form: PhotoShortInterface = {} as PhotoShortInterface;
  uploadInProgress = false;
  uploadInPercents = 0;
  newPhoto = '';
  @Prop(Object) readonly mediaData!: PhotoInterface;

  get photoActive() {
    return this.form.status === 'active';
  }

  set photoActive(value) {
    this.form.status = value ? 'active' : 'inactive';
  }

  mounted() {
    this.form = {
      description: this.mediaData.description,
      file: [],
      performer: this.mediaData.performer.map((x) => x._id) as any,
      sort: 0,
      status: this.mediaData.status,
      name: this.mediaData.name,
    };
  }

  handleFileUpload() {
    const file = (this.$refs.file as any).files[0];
    this.form.file.push(file);
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.newPhoto = e.target.result;
    };
    reader.readAsDataURL(file);
    (this.$refs.file as any).value = '';
  }

  removeImage() {
    this.newPhoto = '';
    this.form.file = [];
  }

  deletePhoto() {
    vxm.model
      .deletePhoto(this.mediaData._id)
      .then(() => {
        if (this.$route.name === 'model-pics') {
          vxm.model.setNeedUpdatePhotoList(true);
        }
        this.close();
      })
      .catch((error) => {
        return error;
      });
  }

  publish() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }
    this.uploadInProgress = true;
    const params = {
      id: this.mediaData._id,
      data: new FormData(),
    } as any;
    this.buildFormData(params.data, this.form);
    if (this.form.file.length) {
      params.uploadProgressCallback = (progressEvent) => {
        Vue.set(this, 'uploadInPercents', Math.round((progressEvent.loaded / progressEvent.total) * 100));
      };
    }
    vxm.model.updatePhoto(params).then(
      () => {
        this.uploadInProgress = false;
        if (this.$route.name === 'model-pics') {
          vxm.model.setNeedUpdatePhotoList(true);
        }
        if (this.form.file.length) {
          this.$notify({
            group: 'foo',
            title: 'Your Upload has finished.',
            duration: 5000,
            type: 'success',
            text:
              'The system will now generate and convert the files for faster access. Conversions might take a while to populate.',
          });
        }
        this.close();
      },
      () => {
        this.uploadInProgress = false;
      },
    );
  }

  close() {
    this.$emit('closed');
  }
}
