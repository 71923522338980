import {Vue, Component} from 'vue-property-decorator';

@Component
export default class DebounceMixin extends Vue{
  // Returns a function, that, as long as it continues to be invoked, will not
  // be triggered. The function will be called after it stops being called for
  // N milliseconds.
  // https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
  debounce(func: any, wait: number) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
}
