








































































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import DropDown from '@/components/DropDown.vue';
import {vxm} from '@/store';
import moment from 'moment';
import DebounceMixin from '@/mixins/DebounceMixin';
import {VideoInterface} from '@/types/videoInterface';
import Loader from '@/components/Loader.vue';
import EditPhoto from '@/components/modals/EditPhoto.vue';
import Paginate from 'vuejs-paginate';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {PhotoInterface} from '@/types/photoInterface';
import PostDetailsModal from '@/components/modals/PostDetailsModal.vue';

@Component({
  components: {ConfirmationModal, DropDown, EditPhoto, Loader, Paginate, PostDetailsModal},
})
export default class ModelVideo extends Mixins(DebounceMixin) {
  isMobile = false;
  query = '';
  totalItems = 0;
  selectedPicture: PhotoInterface | null = null;
  params: any = {
    page: 1,
    take: 15,
    keyword: '',
    performerId: vxm.user.data._id,
    isForSale: false,
  };
  videos: VideoInterface[] = [];
  pictures: PhotoInterface[] = [];
  loading = false;
  debouncedSearch = this.debounce(this.search, 700);
  photoForEdit: PhotoInterface | null = null;
  photoForDelete: PhotoInterface | null = null;
  videoTranscodingError = '';
  timerId = 0;

  @Watch('query') onQueryChange() {
    this.debouncedSearch();
  }

  @Watch('params', {deep: true}) onParamsUpd() {
    const storage = JSON.parse(localStorage.getItem('model') as string);
    this.urlUpdate();
    if (
      storage &&
      storage[this.$route.name as string] &&
      JSON.stringify(this.$route.query) === JSON.stringify(storage[this.$route.name as string].params)
    ) {
      this.pictures = storage[this.$route.name as string].items;
      this.totalItems = storage[this.$route.name as string].count;
      this.getContent(true);
    } else {
      this.getContent();
    }
  }

  @Watch('updNeeded') onUpdNeeded() {
    if (vxm.model.needUpdatePhotoList) {
      this.getContent();
    }
  }

  get user(): ModelDataInterface {
    return vxm.user.data;
  }

  get updNeeded(): boolean {
    return vxm.model.needUpdatePhotoList;
  }

  get pagesCount(): number {
    return Math.ceil(this.totalItems / this.params.take);
  }

  get datesRangeText(): string {
    const diff = moment(this.params.end).diff(this.params.start, 'days');
    switch (diff) {
      case 0:
        return 'Yesterday';
      case 6:
        return 'Last week';
      case 27:
      case 28:
      case 29:
      case 30:
        return 'Last month';
      case 364:
        return 'Last year';
      default:
        return `Last ${diff} days`;
    }
  }

  isMyVids(video): boolean {
    return (video.user && video.user._id === vxm.user.data._id) || video.performer === vxm.user.data._id;
  }

  showSearchBlock() {
    this.$nextTick(() => {
      (this.$refs.searchInput as HTMLInputElement).focus();
    });
  }

  search() {
    this.params.page = 1;
    this.params.keyword = this.query;
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    if (this.$route.name === 'manage-model-videos' || this.$route.name === 'manage-model-tagged-videos') {
      this.params.sort = 'newest';
      this.params.myRole = this.$route.name === 'manage-model-videos' ? 'owner' : 'performer';
    }
    if (this.$route.query) {
      const urlParams = JSON.parse(JSON.stringify(this.$route.query));
      if (urlParams.page) {
        urlParams.page = parseInt(urlParams.page, 10);
      }
      if (urlParams.isSaleVideo) {
        urlParams.isSaleVideo = urlParams.isSaleVideo === 'true';
      }
      this.params = {...this.params, ...urlParams};
      if (this.params.keyword) {
        this.query = this.params.keyword;
      }
    }
  }

  getContent(backgroundUpdate = false) {
    this.loading = !backgroundUpdate;
    if (!backgroundUpdate) {
      clearTimeout(this.timerId);
    }
    const params = JSON.parse(JSON.stringify(this.params));
    Object.keys(params).forEach((key) => {
      if (!params[key] && typeof params[key] !== 'boolean') {
        delete params[key];
      }
    });
    vxm.model
      .getMyPhotos(params)
      .then((res) => {
        this.pictures = res.data.items;
        this.totalItems = res.data.count;
        let storage = JSON.parse(localStorage.getItem('model') as string);
        if (!storage) {
          storage = {};
          storage[this.$route.name as string] = {};
        }
        storage[this.$route.name as string] = {
          params: this.$route.query,
          items: this.videos,
          count: this.totalItems,
        };
        localStorage.setItem('model', JSON.stringify(storage));
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.loading = false;
        this.timerId = setTimeout(() => {
          this.getContent(true);
        }, 30000);
      });
  }

  destroyed() {
    clearTimeout(this.timerId);
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    this.isMobile = window.innerWidth <= 823;
  }

  formatDate(date): string {
    return moment(date).format('MMM DD YYYY @ HH.mm');
  }

  videoCategory(video) {
    return typeof video.price === 'number' ? (video.isSaleVideo ? 'vids-plus' : 'vids') : 'clips';
  }

  deletePhoto(confirmed?: boolean) {
    if (confirmed && this.photoForDelete) {
      vxm.model
        .deletePhoto(this.photoForDelete._id)
        .then(() => {
          this.getContent();
        })
        .catch((error) => {
          return error;
        });
    }
    this.photoForDelete = null;
  }

  switchPhotoStatus(photo: PhotoInterface, id) {
    const img = JSON.parse(JSON.stringify(photo));
    const params = {
      id: img._id,
      data: new FormData(),
    };
    params.data.append('status', img.status === 'active' ? 'inactive' : 'active');
    vxm.model
      .updatePhoto(params)
      .then((res) => {
        this.pictures[id].status = res.data.status;
      })
      .catch((error) => {
        return error;
      });
  }

  urlUpdate() {
    const params = JSON.parse(JSON.stringify(this.params));
    Object.keys(params).forEach((key) => {
      if (typeof params[key] !== 'boolean' && !params[key]) {
        delete params[key];
      }
      if (typeof params[key] === 'boolean') {
        params[key] = params[key] + '';
      }
    });
    delete params.take;
    delete params.performerId;
    delete params.isForSale;
    params.page += '';
    if (JSON.stringify(params) !== JSON.stringify(this.$route.query)) {
      this.$router.replace({query: params});
    }
  }
}
