import {Component, Vue} from 'vue-property-decorator';

@Component
export default class BuildFormDataMixin extends Vue {
  buildFormData(formData, data, parentKey?) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      if (data || data === '' || data === false) {
        formData.append(parentKey, data);
      }
    }
  }
}
